import React from 'react';
import './index.css';
import "./styles/main.scss";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home.js";
import Login from './pages/Login';
import DashBoard from './layouts/DashBoard';
import ExistingDetails from './pages/ExistingDetails';
import TotalReports from './pages/TotalReports';
import ITReports from './pages/ITReports';
import Profile from './components/Profile';
import ChangePassword from './components/ChangePassword';
import PrivateRoute from "./components/PrivateRoute.js";
import { AuthProvider } from './components/AuthProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Login />} />

                    <Route element={<PrivateRoute />}>
                        <Route path="/dashboard" element={<DashBoard />}>
                            <Route path="/dashboard/home" element={<Home />} />
                            <Route path="/dashboard/existing" element={<ExistingDetails />} />
                            <Route path="/dashboard/total-reports" element={<TotalReports />} />
                            <Route path="/dashboard/IT-reports" element={<ITReports />} />
                        </Route>
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/changepassword" element={<ChangePassword />} />
                    </Route>
                </Routes>
                <ToastContainer />
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
