import { Create } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import instance from "../utils/api";
import TopNavbar from "../layouts/TopNavbar";

const cl ="app-page-profile";
function ChangePassword(){
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleChangePassword = async () => {
        const formData = {
            old_password:currentPassword,
            new_password:newPassword,
            confirm_new_password:confirmPassword,
        };

        try {
            const response = await instance.post('user/changePassword', formData);
            console.log('Password change response:', response.data);
            toast.success('Password updated succesfully');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Error changing password:', error);
            toast.error('Error changing password');
        }
    };

    return(
        <>
            <div className="image-upload-page">
                <TopNavbar/>
            </div>
            <div className={`${cl}`}>
                <div className={`${cl}__wrapper`}>
                    <div className={`${cl}__card`}>
                        <h2>Change Password</h2>
                        <div style={{marginTop:10}} className={`${cl}__info`}>
                            <ul className={`${cl}__infolist`}>
                                <li className={`${cl}__infolistitem`}>
                                    <TextField
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        id="standard-basic"
                                        label="Old Password"
                                        variant="standard"
                                    />
                                </li>
                                <li className={`${cl}__infolistitem`}>
                                <TextField
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        id="standard-basic"
                                        label="New Password"
                                        variant="standard"
                                    />
                                </li>
                                <li className={`${cl}__infolistitem`}>
                                <TextField
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        id="standard-basic"
                                        label="Verify Password"
                                        variant="standard"
                                    />
                                </li>
                            </ul>
                            <div className="submit_button_1 submit_button_2">
                                <Button className="custom-button submit" onClick={handleChangePassword} variant="contained">
                                    Update Password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </> 
    )
}
export default ChangePassword;
