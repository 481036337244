import React, { useState, useEffect } from 'react';
import { Create } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import instance from '../utils/api';
import TopNavbar from '../layouts/TopNavbar';
import user from '../images/user.png'
const cl = "app-page-profile";

function Profile() {
    const [profileData, setprofileData] = useState('');
    const [name, setName] = useState('');
    const NewaccessToken = localStorage.getItem("NewaccessToken");
    useEffect(() => {
    

            async function fetchProfileData() {
                try {
                    const response = await instance.post(`/User/profile`,{
                        headers: {
                          Authorization: NewaccessToken,
                        },
                      });
                    setprofileData(response.data);
                   console.log('ppp',profileData)
                    setName(profileData.username);
                   
                    console.log(profileData)
                } catch (error) {
                    console.error('Error fetching profile data:', error);
                }
            }

            fetchProfileData();
        
    }, []);

    


    return (
        <>
            <div className="profile-page">
                <TopNavbar />
            </div>
            <div className={`${cl}`}>
                <div className={`${cl}__wrapper`}>
                    <div className={`${cl}__card`}>
                        <div className={`${cl}__image`}>
                          
                            <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                                <img
                                    src={user}
                                    alt="Profile"
                                />
                            </label>
                           

                        </div>




                        <div className={`${cl}__info`}>
                            <ul className={`${cl}__infolist`}>
                                <li className={`${cl}__infolistitem`}>
                                    <h6>Email</h6>
                                    <p>{profileData.email}</p>
                                </li>
                                <li className={`${cl}__infolistitem`}>
                                    <h6>Name</h6>
                                    <p>{profileData.username}</p>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;

