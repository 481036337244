import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
 
const PDFDocument = ({ formData }) => {
  console.log(formData)
  const formattedDate = new Date(formData.donation_date).toLocaleDateString('en-GB');
 
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    headerText: {
      fontSize: 14,
      textAlign: 'center',
    },
    headerSno: {
      fontSize: 14,
      textAlign: 'left',
    },
    headerDate: {
      fontSize: 14,
      textAlign: 'right',
    },
    title: {
      fontSize: 25,
      textAlign: 'center',
      color: 'red',
      fontWeight: 'bold'
    },
    footer: {
      fontSize: 14,
      textAlign: 'start',
 
    },
    address: {
      fontSize: 12,
      textAlign: 'center',
 
    },
    content: {
      fontSize: 12,
      textAlign: 'left',
    },
    content1: {
      fontSize: 12,
      textAlign: 'center',
    },
 
    greenLine: {
      borderTop: '2px solid green',
      marginTop: 10,
      marginBottom: 5,
    },
    imageContainer: {
      flexDirection: 'row',
    },
    image: {
      width: 50,
      height: 50,
    },
 
    submittedData: {
      fontSize: 12,
      textAlign: 'left',
      marginBottom: '10px',
    },
    submittedData1: {
      fontSize: 12,
      textAlign: 'left',
      marginBottom: '10px',
    },
    border: {
      borderBottom: '2px solid green',
      marginTop: 5,
      marginBottom: 10,
    },
    borderline: {
      borderBottom: '2px solid green',
      marginTop: 5,
      marginBottom: 10,
    },
    align: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginLeft: '100px',
    },
    colalign: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems:'flex-start',
      margin:'0px 60px',
     
    }
  });
  const generateSNo = (id, donationDate) => {
    const year = dayjs(donationDate).year();
    const paddedId = id.toString().padStart(4, '0');
    return `SDVVG-${year}-${paddedId}`;
  };
  return (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerSno}>S.NO: {generateSNo(formData.id, formData.donation_date)}</Text>
          <Text style={styles.headerText}>RECEIPT</Text>
          <Text style={styles.headerDate}>Date:{formattedDate || 'N/A'}</Text>
        </View>
        <View style={styles.imageContainer}>
          <Image src={'/images/logo.jpeg'} style={styles.image} />
          <View style={styles.align}>
            <Text style={styles.title}>SRI DATTATREYA VEDA VIDYA GURUKULAM</Text>
 
 
 
 
            <Text style={styles.content}>
              D.No. 115-17-79, Veda Godavari Marg, Seetharama Agraharam, Konthamuru,
            </Text>
            <Text style={styles.content}>
              Rajahmahendravaram - 533102.
            </Text>
            <Text style={styles.content}>
              Cell: 9849014404, 9049045678
            </Text>
          </View>
        </View>
        <View style={styles.border}></View>
 
        <Text style={styles.content1}>sdvvgurukulam@gmail.com</Text>
 
        <Text style={styles.content1}>http://dattatreyavedagurukulam.org/</Text>
        <View style={styles.border}></View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Surname:{' '}
            <Text style={styles.borderline}>{formData.surname}</Text>
          </Text>
          <Text   style={styles.submittedData1}>
            Gothram:{' '}
            <Text  style={styles.borderline}>{formData.gothram}</Text>
          </Text>
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Name of the Donor:{' '}
            <Text style={styles.borderline}>{formData.name}</Text>
          </Text>
 
          <Text   style={styles.submittedData1}>
            Address:{' '}
            <Text style={styles.borderline}>{formData.address}</Text>
          </Text>
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Donation Type:{' '}
            <Text style={styles.borderline}>
              {formData.donation_type}
            </Text>
          </Text>
          <Text   style={styles.submittedData1}>
            Reference Number:{' '}
            <Text style={styles.borderline}>
              {formData.ReferenceNo}
            </Text>
          </Text>
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Donation Amount:{' '}
            <Text style={styles.borderline}>{formData.amount}</Text>
          </Text>
 
          <Text   style={styles.submittedData1}>
            Rupees in Words:{' '}
            <Text style={styles.borderline}>{formData.amount_in_words}</Text>
          </Text>
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Purpose of Donation:{' '}
            <Text style={styles.borderline}>{formData.PurposeofDonation}</Text>
          </Text>
          <Text style={styles.submittedData}>
            Pan Number:{' '}
            <Text style={styles.borderline}>{formData.pan}</Text>
          </Text>
         
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Cell No:{' '}
            <Text style={styles.borderline}>{formData.phonenumber}</Text>
          </Text>
          <Text   style={styles.submittedData1}>
            Whatsapp:{' '}
            <Text style={styles.borderline}>{formData.whatsupnumber}</Text>
          </Text>
 
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Email:{' '}
            <Text style={styles.borderline}>{formData.email}</Text>
          </Text>
          <Text   style={styles.submittedData1}>
            Aadhar:{' '}
            <Text style={styles.borderline}>{formData.adhar}</Text>
          </Text>
        </View>
        <View style={styles.colalign}>
          <Text style={styles.submittedData}>
            Referred by:{' '}
            <Text style={styles.borderline}>{formData.reffered_by}</Text>
          </Text>
          <Text  style={styles.submittedData1}>
            Referred person Number:{' '}
            <Text style={styles.borderline}>{formData.reffered_person_number}</Text>
          </Text>
        </View>
 
        <View style={styles.border}></View>
        <View style={styles.colalign}>
          <Text style={styles.footer}>Donations exempt from income Tax u/s 80G of the Income Tax act, 1961</Text>
          <Text style={styles.footer}>Treasurer</Text>
        </View>
        <View style={styles.border}></View>
      </Page>
    </Document>
  );
};
 
export default PDFDocument;
 
