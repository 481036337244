import React, { useEffect, useState, useMemo } from "react";
import {
  createMRTColumnHelper,
  MaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import instance from "../utils/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { FormControl, Grid, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
dayjs.extend(customParseFormat);
const columnHelper = createMRTColumnHelper();

function TotalReports() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());

  const [searchByMobile, setSearchByMobile] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [showData, setShowData] = useState(false);
  const NewaccessToken = localStorage.getItem("NewaccessToken");
  const fetchData = async () => {
    try {
      const formattedFromDate = dayjs(fromDate).format("YYYY-MM-DD");
      const formattedToDate = dayjs(toDate).format("YYYY-MM-DD");

      const response = await instance.get("/Donation/list", {
        params: {
          donation_from_date: formattedFromDate,
          donation_to_date: formattedToDate,
          name: searchByName,
        },
        headers: {
          Authorization: NewaccessToken,
        },
      });

      setData(response.data.rows);
      setShowData(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = () => {
    fetchData();
  };
  const handleDeletedata = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this donation?"
    );
    if (confirmed) {
      try {
        await instance.delete(`Donation/delete/${id}`,{
          headers: {
            Authorization: NewaccessToken,
          },
        });
        console.log(`Donation with ID ${id} deleted successfully.`);

        // Filter out the deleted row
        setData((prevData) => prevData.filter((row) => row.id !== id));
      } catch (error) {
        console.error(`Error deleting donation with ID ${id}:`, error);
        console.error(error.response);
        console.error(error.message);
      }
    }
  };

  const [selectedDonationIds, setSelectedDonationIds] = useState([]);

  const handleSelectDonation = (id) => {
    setSelectedDonationIds((prevSelection) => {
      if (prevSelection.includes(id)) {
        return prevSelection.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelection, id];
      }
    });
  };

  const handleBulkDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete selected donations?"
    );
    if (confirmed) {
      const idsParam = selectedDonationIds.join(",");

      try {
        await instance.delete(`/Donation/bulkdelete/${idsParam}`,{
          headers: {
            Authorization: NewaccessToken,
          },
        });

        setData((prevData) =>
          prevData.filter((row) => !selectedDonationIds.includes(row.id))
        );

        setSelectedDonationIds([]);
      } catch (error) {
        console.error("Error deleting selected donations:", error);
        console.error(error.response);
        console.error(error.message);
      }
    }
  };

  const handleEditData = async (id) => {
    try {
      const response = await instance.get(`/Donation/view/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: NewaccessToken,
        },
      });

      if (response.status === 200) {
        const editdetails = response.data;

        navigate("/dashboard/home", { state: { editdetails } });
      } else {
        console.error("Error fetching details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("select", {
        header: (
          <input
            type="checkbox"
            onChange={() => {
              const allIds = data.map((row) => row.id);
              const allSelected = selectedDonationIds.length === allIds.length;
              setSelectedDonationIds(allSelected ? [] : allIds);
            }}
            checked={selectedDonationIds.length === data.length}
          />
        ),
        Cell: ({ row }) => (
          <input
            type="checkbox"
            onChange={() => handleSelectDonation(row.original.id)}
            checked={selectedDonationIds.includes(row.original.id)}
          />
        ),
        size: 40,
      }),
      // columnHelper.accessor("id", {
      //   header: "ID",
      //   size: 40,
      //   Cell: ({ renderedCellValue }) => (
      //     <span className="custom-table-cell-text">{renderedCellValue}</span>
      //   ),
      // }),
      columnHelper.accessor("name", {
        header: "Name",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("surname", {
        header: "Surname",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("phonenumber", {
        header: "Phone Number",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("whatsupnumber", {
        header: "WhatsApp Number",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("adhar", {
        header: "Aadhar Number",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("email", {
        header: "Email",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("address", {
        header: "Address",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("reffered_by", {
        header: "Referred By",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("reffered_person_number", {
        header: "Referred Person Number",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("gothram", {
        header: "Gothram",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("donation_type", {
        header: "Donation Type",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("amount", {
        header: "Amount",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("amount_in_words", {
        header: "Amount in Words",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
      columnHelper.accessor("donation_date", {
        header: "Donation Date",
        Cell: ({ renderedCellValue }) => (
          <span className="custom-table-cell-text">{renderedCellValue}</span>
        ),
      }),
    ],
    [data, selectedDonationIds]
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const exportCsv = async (rows, exportAll) => {
    const rowData = exportAll ? data : rows.map((row) => row.original);

    if (rowData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const csv = generateCsv(csvConfig)(rowData);
    await download(csvConfig)(csv);
  };
  const handleExportRows = (rows, exportAll) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };

  const handleFromDateChange = (newDate) => {
    setFromDate(dayjs(newDate));
  };

  const handleToDateChange = (newDate) => {
    setToDate(dayjs(newDate));
  };

  const handleSearchByMobileChange = (e) => {
    setSearchByMobile(e.target.value);
  };
  console.log(fromDate, toDate);
  const renderRowActionMenuItems = ({ row, closeMenu }) => [
    <MenuItem
      key={0}
      sx={{ m: 0 }}
      onClick={() => handleEditData(row.original.id)}
    >
      Edit
    </MenuItem>,
    <MenuItem
      key={1}
      sx={{ m: 0 }}
      onClick={() => handleDeletedata(row.original.id)}
    >
      Delete
    </MenuItem>,
  ];
  return (
    <div className="search-container">
      <h2>Total Reports</h2>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="new_donation">
            <label>From Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="custom-input"
                value={fromDate}
                onChange={(newDate) => handleFromDateChange(dayjs(newDate))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={params.onClickToggle}
                            size="large"
                            edge="start"
                          >
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="new_donation">
            <label>To Date</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="custom-input"
                value={toDate}
                onChange={(newDate) => handleToDateChange(dayjs(newDate))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={params.onClickToggle}
                            size="large"
                            edge="start"
                          >
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="new_donation">
            <label>Search by Name</label>
            <TextField
              type="text"
              name="searchByName"
              placeholder="search by name"
              value={searchByName}
              onChange={(e) => setSearchByName(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
      <div className="submit_button_1">
        <Button disableRipple disableElevation
          onClick={handleSearch}
          type="button"
          className="custom-button submit"
        >
          Search
        </Button>
      </div>
      {showData && (
        <div style={{ marginTop: "20px" }}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableFacetedValues
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  padding: "8px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() =>
                    exportCsv(table.getPrePaginationRowModel().rows, false)
                  }
                  startIcon={<FileDownloadIcon />}
                  variant="outlined"
                >
                  Export All Rows (Excel)
                </Button>
                {/* <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, false)}
                  startIcon={<FileDownloadIcon />}
                  variant="outlined"
                >
                  Export All Rows (PDF)
                </Button> */}
                <Button
                  disabled={selectedDonationIds.length === 0}
                  onClick={handleBulkDelete}
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                >
                  Delete Selected
                </Button>
              </Box>
            )}
            enableRowActions
            renderRowActionMenuItems={renderRowActionMenuItems}
            positionActionsColumn="last"
          />
        </div>
      )}
    </div>
  );
}

export default TotalReports;
