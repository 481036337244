import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from '../utils/api';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/pages/existing.css'
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';


function ExistingDetails() {
  const navigate = useNavigate();
  const NewaccessToken = localStorage.getItem("NewaccessToken");
  const [searchName, setSearchName] = useState('');

  const handleSearchClick = async () => {
    if (!searchName.trim()) {
      toast.error('Please fill in the details');
      return;
    }
    try {
      const response = await instance.get(`/Donation/list?name=${searchName}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: NewaccessToken,
        },
      });

      if (response.status === 200) {
       
        const detailsArray = response.data.rows;

       
        const firstDetail = detailsArray.length > 0 ? detailsArray[0] : null;
console.log(firstDetail)
       
        navigate('/dashboard/home', { state: { details: firstDetail } });
      } else {
        console.error('Error fetching details');
      
      }
    } catch (error) {
      console.error('Error fetching details:', error);
     
    }
  };

  const handleInputChange = (event) => {
    setSearchName(event.target.value);
  };

  return (
    <div className="search-container">
      <h2>Existing Details</h2>
      <Grid container spacing={2}>
            <Grid item xs={6}>
                <div className="new_donation">
                    <label>Search By Name</label>
                    <TextField name='searchByName' value={searchName} onChange={handleInputChange} id="outlined-basic" label="" variant="outlined" />
                </div>
                <div className='submit_button_1'>
                <Button disableRipple disableElevation className='custom-button submit' type="button" onClick={handleSearchClick}>Search</Button>
                </div>
              </Grid>
          </Grid>
    </div>
  );
}

export default ExistingDetails;

