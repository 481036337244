import React, { useState } from 'react';
import { Button } from "@mui/material";
import { CgUserAdd } from 'react-icons/cg'
import { Link, useNavigate } from "react-router-dom";
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { TbReportSearch } from 'react-icons/tb'
import NotificationsIcon from "../Icons/NotificationsIcon";
import {AiFillTag} from 'react-icons/ai';
import newentry from '../images/new-entry.png';
import details from '../images/details.png';
import reports from '../images/report.png';
import totalreports from '../images/total-details.png';
import itreports from '../images/it-reports.png';

function SideBar({ userRole }) {
    const navigate = useNavigate();
    const [isSubmenuOpen, setSubmenuOpen] = useState(false);

    const toggleSubmenu = () => {
        setSubmenuOpen(!isSubmenuOpen);
    };

    return (
        <>
            <div className="app-sidebar">
                <div className="app-sidebar-logo">
                    <img src="/images/logo.jpeg" alt="Logo" />
                </div>
                <div className="app-sidebar-menu">
                    <ul className="app-sidebar-menu-list">
                        <li className="app-sidebar-menu-list-item">
                            <Link to="/Dashboard/home">
                                <Button className="active">
                                    <img src={newentry}/><span className="app-sidebar-menu-title">New Entry</span>
                                </Button>
                            </Link>
                        </li>


                        <li className="app-sidebar-menu-list-item">
                            <Link to="/Dashboard/existing">
                                <Button>
                                <img src={details}/><span className="app-sidebar-menu-title">Existing Details</span>
                                </Button>
                            </Link>
                        </li>
                        <li className="app-sidebar-menu-list-item">
                            <Link onClick={toggleSubmenu}>
                                <Button>
                                <img src={reports}/><span className="app-sidebar-menu-title">Reports</span>
                                </Button>
                            </Link>
                            {isSubmenuOpen && (
                                <ul className="sub-menu">
                                    <li className="sub-menu-item">
                                        <Link to="/Dashboard/total-reports"><img src={totalreports}/>Total Reports</Link>
                                    </li>
                                    <li className="sub-menu-item">
                                        <Link to="/Dashboard/IT-reports"><img src={itreports}/>IT Reports</Link>
                                    </li>

                                </ul>
                            )}
                        </li>

                    </ul>
                </div>
            </div>
        </>
    );
}

export default SideBar;
