import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import user from '../images/user.png'
function TopNavbar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('NewaccessToken');
    localStorage.removeItem('userRole');
    navigate('/login');
  };
  const handleChangeProfile = () => {
   
    navigate('/profile');
  };
  const handleChangePassword = () => {
    navigate('/changepassword');
  
  };

  return (
    <>
      <div className="app-top-navbar">
        <div className="app-top-navbar__left"></div>
        <div className="app-top-navbar__right">
          <ul className="app-top-navbar__right--list">
            <li className="app-top-navbar__right--listitem">
              <div className="web-logout">
                <Button disableRipple disableElevation
                  id="profile-button"
                  aria-controls={open ? 'profile-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                <img width={'40px'} src={user}/>
                </Button>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'profile-button',
                  }}
                >
                  <MenuItem onClick={handleChangeProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TopNavbar;
