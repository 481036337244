import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from '../components/PDFDocument';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputAdornment from '@mui/material/InputAdornment';
import instance from '../utils/api';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import '../styles/pages/home.css'
import { Button } from '@mui/material';
 
 
 
 
 
 
function NewEntry() {
  const NewaccessToken = localStorage.getItem("NewaccessToken")
  const location = useLocation();
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);
 
  const [donationDate, setDonationDate] = useState(null);
  const [gothram, setGothram] = useState('');
  const [surname, setSurname] = useState('');
  const [donorName, setDonorName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [donationType, setDonationType] = useState('');
  const [donationAmount, setDonationAmount] = useState('');
  const [rupeesInWords, setRupeesInWords] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [referredPersonNumber, setReferredPersonNumber] = useState('');
  const [formData, setFormData] = useState(null);
  const [donationPurpose, setDonationPurpose] = useState('');
  const [otherInput, setOtherInput] = useState('');
 
  const handleShowPDF = () => {
    setPdfVisible(true);
  };
  const [forceUpdate, setForceUpdate] = useState(false);
  const handleDonationTypeChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected Donation Type:", selectedValue);
    setDonationType(selectedValue);
    setForceUpdate((prev) => !prev);
    setOtherInput('');
  };
  useEffect(() => {
    console.log("Current donationType:", donationType);
  }, [donationType, forceUpdate]);
 
  const handleDateOfDonationChange = (newDate) => {
    setDonationDate(newDate);
  };
 
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'gothram':
        setGothram(value);
        break;
      case 'surname':
        setSurname(value);
        break;
      case 'donorName':
        setDonorName(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'whatsappNumber':
        setWhatsappNumber(value);
        break;
      case 'aadharNumber':
        setAadharNumber(value);
        break;
        case 'panNumber':
        setPanNumber(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'donationType':
        setDonationType(value);
 
        setOtherInput('');
        break;
      case 'donationPurpose':
        setDonationPurpose(value);
        break;
      case 'donationAmount':
        setDonationAmount(value);
        break;
      case 'otherInput':
        setOtherInput(value);
        break;
      case 'donationPurpose':
        setDonationPurpose(value);
        break;
      case 'rupeesInWords':
        setRupeesInWords(value);
        break;
      case 'referredBy':
        setReferredBy(value);
        break;
      case 'referredPersonNumber':
        setReferredPersonNumber(value);
        break;
      default:
        break;
    }
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const aadharRegex = /^\d{12}$/;
    if (
      !donationDate ||
      !gothram ||
      !surname ||
      !donorName ||
      !phoneNumber ||
      !whatsappNumber || 
      !address ||
      !donationType ||
      !donationAmount ||
      !donationPurpose ||
      (donationType !== 'CASH' && !otherInput) ||
      !rupeesInWords
    ) {
      toast.error('Please fill required fields');
      return;
    }
    const isAadharOrPanEntered = (aadharNumber, panNumber) => {
      return aadharNumber.trim() !== '' || panNumber.trim() !== '';
    };
    
    if (!isAadharOrPanEntered(aadharNumber, panNumber)) {
      toast.error('Please enter Aadhar or Pan number');
      return;
    }
    const isPhoneNumberValid = (phoneNumber) => {
      const phoneRegex = /^\d{10}$/; // Modify the regex as needed
      return phoneNumber.trim() === '' || phoneRegex.test(phoneNumber);
    };
    
    const isWhatsappNumberValid = (whatsappNumber) => {
      const phoneRegex = /^\d{10}$/; // Modify the regex as needed
      return whatsappNumber.trim() === '' || phoneRegex.test(whatsappNumber);
    };
    
    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return email.trim() === '' || emailRegex.test(email);
    };
    
    const isAadharValid = (aadharNumber) => {
      const aadharRegex = /^\d{12}$/; // Modify the regex as needed
      return aadharNumber.trim() === '' || aadharRegex.test(aadharNumber);
    };
    const isPanValid = (panNumber) => {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      return panNumber.trim() === '' || panRegex.test(panNumber.toUpperCase());
    };
    
    
    const isOtherNumberValid = (referredPersonNumber) => {
      const phoneRegex = /^\d{10}$/; // Modify the regex as needed
      return referredPersonNumber.trim() === '' || phoneRegex.test(referredPersonNumber);
    };

    if (!isPhoneNumberValid(phoneNumber)) {
      toast.error('Invalid phone number');
      return;
    }
    
    if (!isWhatsappNumberValid(whatsappNumber)) {
      toast.error('Invalid WhatsApp number');
      return;
    }
    
    if (!isEmailValid(email)) {
      toast.error('Invalid email');
      return;
    }
    
    if (!isAadharValid(aadharNumber)) {
      toast.error('Enter valid Aadhar number');
      return;
    }
    if (!isPanValid(panNumber)) {
      toast.error('Enter valid Pan number');
      return;
    }
    
    if (!isOtherNumberValid(referredPersonNumber)) {
      toast.error('Invalid referred phone number');
      return;
    }
    try {
      const requestData = {
        donation_date: dayjs(donationDate).format('YYYY-MM-DD'),
        gothram,
        surname,
        name: donorName,
        phonenumber: phoneNumber,
        whatsupnumber: whatsappNumber,
        adhar: aadharNumber,
        pan:panNumber,
        email,
        address,
        donation_type: donationType,
        ReferenceNo:otherInput,
        amount: donationAmount,
        purpose: donationPurpose,
        amount_in_words: rupeesInWords,
        reffered_by: referredBy,
        reffered_person_number: referredPersonNumber,
      };
 
      if (location.state && location.state.editdetails) {
        const editDetailsData = location.state.editdetails;
        const response = await instance.patch(`Donation/update/${editDetailsData.id}`, requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: NewaccessToken,
          },
        });
 
        if (response.status === 200) {
          setPdfVisible(true);
          toast.success('Form updated successfully');
 
          setFormData({
            id: response.data.id,
            donation_date: dayjs(donationDate).format('YYYY-MM-DD'),
            gothram,
            surname,
            name: donorName,
            phonenumber: phoneNumber,
            whatsupnumber: whatsappNumber,
            adhar: aadharNumber,
            pan: panNumber,
            email,
            address,
            donation_type: donationType,
            ReferenceNo:otherInput,
            amount: donationAmount,
            PurposeofDonation: donationPurpose,
            amount_in_words: rupeesInWords,
            reffered_by: referredBy,
            reffered_person_number: referredPersonNumber,
          });
 
          setDonationDate(null);
          setGothram('');
          setSurname('');
          setDonorName('');
          setPhoneNumber('');
          setWhatsappNumber('');
          setAadharNumber('');
          setPanNumber('');
          setEmail('');
          setAddress('');
          setDonationType('');
          setOtherInput('');
          setDonationAmount('');
 
          setDonationPurpose('');
          setRupeesInWords('');
          setReferredBy('');
          setReferredPersonNumber('');
        } else {
          console.error('Form update failed');
          toast.error('Form update failed');
        }
      } else {
        const response = await instance.post('Donation/create', requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: NewaccessToken,
          },
        });
 
        if (response.status === 201) {
          setPdfVisible(true);
          toast.success('Form submitted successfully');
 
          setFormData({
            id: response.data.id,
            donation_date: dayjs(donationDate).format('YYYY-MM-DD'),
            gothram,
            surname,
            name: donorName,
            phonenumber: phoneNumber,
            whatsupnumber: whatsappNumber,
            adhar: aadharNumber,
            pan: panNumber,
            email,
            address,
            donation_type: donationType,
            amount: donationAmount,
            ReferenceNo: otherInput,
            PurposeofDonation: donationPurpose,
            amount_in_words: rupeesInWords,
            reffered_by: referredBy,
            reffered_person_number: referredPersonNumber,
          });
 
          setDonationDate(null);
          setGothram('');
          setSurname('');
          setDonorName('');
          setPhoneNumber('');
          setWhatsappNumber('');
          setAadharNumber('');
          setPanNumber('');
          setEmail('');
          setAddress('');
          setDonationType('');
          setOtherInput('');
          setDonationAmount('');
          setDonationPurpose('');
          setRupeesInWords('');
          setReferredBy('');
          setReferredPersonNumber('');
        } else {
          console.error('Form submission failed');
          toast.error('Form submission failed');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form');
    }
  };
 
 
  useEffect(() => {
    if (pdfVisible && formData && formData.donation_date && donationDate) {
      const formattedDate = dayjs(donationDate).format('YYYY-MM-DD');
 
      if (formattedDate !== formData.donation_date) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          donation_date: formattedDate,
        }));
      }
    }
  }, [pdfVisible, donationDate, formData]);
  const handleCancel = () => {
    setDonationDate(null);
    setGothram('');
    setSurname('');
    setDonorName('');
    setPhoneNumber('');
    setWhatsappNumber('');
    setAadharNumber('');
    setPanNumber('');
    setEmail('');
    setAddress('');
    setDonationType('');
    setOtherInput('');
    setDonationAmount('');
    setDonationPurpose('');
    setRupeesInWords('');
    setReferredBy('');
    setReferredPersonNumber('');
    setFormData(null);
  };
 
  useEffect(() => {
    console.log(location.state)
    if (location.state) {
      const { details, editdetails } = location.state;
      if (details) {
        setDonationDate(details.donation_date ? dayjs(details.donation_date) : null);
        setGothram(details.gothram || '');
        setSurname(details.surname || '');
        setDonorName(details.name || '');
        setPhoneNumber(details.phonenumber || '');
        setWhatsappNumber(details.whatsupnumber || '');
        setAadharNumber(details.adhar || '');
        setPanNumber(details.pan || '');
        setEmail(details.email || '');
        setAddress(details.address || '');
        setDonationType(details.donation_type || '');
        setOtherInput(details.ReferenceNo || '');
        setDonationAmount(details.amount || '');
        setDonationPurpose(details.PurposeofDonation || '');
        setRupeesInWords(details.amount_in_words || '');
        setReferredBy(details.reffered_by || '');
        setReferredPersonNumber(details.reffered_person_number || '');
      }
      else if (editdetails) {
        setDonationDate(editdetails.donation_date ? dayjs(editdetails.donation_date) : null);
        setGothram(editdetails.gothram || '');
        setSurname(editdetails.surname || '');
        setDonorName(editdetails.name || '');
        setPhoneNumber(editdetails.phonenumber || '');
        setWhatsappNumber(editdetails.whatsupnumber || '');
        setAadharNumber(editdetails.adhar || '');
        setPanNumber(editdetails.pan || '');
        setEmail(editdetails.email || '');
        setAddress(editdetails.address || '');
        setDonationType((editdetails.donation_type || '').toUpperCase());
        setOtherInput(editdetails.ReferenceNo || '');
        setDonationAmount(editdetails.amount || '');
        setDonationPurpose(editdetails.PurposeofDonation || '');
        setRupeesInWords(editdetails.amount_in_words || '');
        setReferredBy(editdetails.reffered_by || '');
        setReferredPersonNumber(editdetails.reffered_person_number || '');
      }
    }
 
 
  }, [location.state]);
 
 
  return (
    <div className="form-container">
      {pdfVisible ? (
        <PDFViewer width="100%" height={600}>
          <PDFDocument formData={formData} />
        </PDFViewer>
        
      ) : (
        <form className='main_form' onSubmit={handleSubmit}>
          <div className="">
            <div className="new_entry_details">
             <div className='new_entry_details_title'>
             <h2>New Entry</h2>
             </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className='new_donation'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        className="custom-input"
                        value={donationDate}
                        onChange={handleDateOfDonationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={params.onClickToggle}
                                    size="large"
                                    edge="start"
                                  >
                                    <CalendarTodayIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                </div>
              </Grid>
                <Grid item xs={6}>
                    <div className='new_donation'>
                        <TextField name='gothram'  value={gothram} onChange={handleInputChange} id="outlined-basic" label="Gothram *" variant="outlined" />
                    </div>
                  </Grid>
           
                <Grid item xs={6}>
                    <div className="new_donation">
                      <TextField name='surname' value={surname} onChange={handleInputChange} id="outlined-basic" label="Surname *" variant="outlined" />
                  </div>
              </Grid>
                <Grid item xs={6}>
                  <div className="new_donation">
                      <TextField name='donorName' value={donorName} onChange={handleInputChange} id="outlined-basic" label="Donorname *" variant="outlined" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='phoneNumber' value={phoneNumber} onChange={handleInputChange} id="outlined-basic" label="Phone Number *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='whatsappNumber' value={whatsappNumber} onChange={handleInputChange} id="outlined-basic" label="Whatsapp Number *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='aadharNumber' value={aadharNumber} onChange={handleInputChange} id="outlined-basic" label="Aadhar Number *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='panNumber' value={panNumber} onChange={handleInputChange} id="outlined-basic" label="Pan Number *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='email' value={email} onChange={handleInputChange} id="outlined-basic" label="Email" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='address' value={address} onChange={handleInputChange} id="outlined-basic" label="Address *" variant="outlined" />
                </div>
                </Grid>
               
                <Grid item xs={6}>
                <div className="new_donation">
                <select
                    name="donationType"
                    className="custom-select"
                    value={donationType}
                    onChange={handleDonationTypeChange}
                  >
                    {donationType === null || donationType === '' ? (
                      <option value="" disabled>Select Donation Type *</option>
                    ) : null}
                    <option value="UPI">UPI</option>
                    <option value="CASH">Cash</option>
                    <option value="CHEQUE">Cheque</option>
                    <option value="DD">DD</option>
                    <option value="ONLINE">Online Payment</option>
                  </select>
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                {donationType && donationType !== 'CASH' && (
                <TextField name='otherInput' className="custom-input half-width" value={otherInput} onChange={handleInputChange} id="outlined-basic" label="Reference No" variant="outlined" />
                )}
                </div>
                </Grid>
                <Grid item xs={6}>  
                <div className="new_donation">
                <TextField name='donationAmount' value={donationAmount} onChange={handleInputChange} id="outlined-basic" label="Donation Amount *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='rupeesInWords' value={rupeesInWords} onChange={handleInputChange} id="outlined-basic" label="Rupees in words *" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='referredBy' value={referredBy} onChange={handleInputChange} id="outlined-basic" label="Referred By" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='referredPersonNumber' value={referredPersonNumber} onChange={handleInputChange} id="outlined-basic" label="Referred Person Number" variant="outlined" />
                </div>
                </Grid>
                <Grid item xs={6}>
                <div className="new_donation">
                <TextField name='donationPurpose' value={donationPurpose} onChange={handleInputChange} id="outlined-basic" label="Purpose of donation *" variant="outlined" />
                </div>
                </Grid>
                </Grid>
            </div>
          </div>
          <div className="cancel_submit_button">
            <Button disableRipple disableElevation type="button" className="custom-button cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button disableRipple disableElevation type="submit" className="custom-button submit">
              {location.state && location.state.editdetails ? 'Update' : 'Submit'}
            </Button>
          </div>
        </form>
      )}
      <ToastContainer />
    </div>
  );
}
 
export default NewEntry;
