import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Grid, Box, Paper, CssBaseline, Typography, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { noauthinstance } from '../utils/api';
import { useAuth } from '../components/AuthProvider';
import login_logo from '../../src/logo.jpeg';
import '../styles/pages/login.css';
import bgImage from "../../src/images/bg.jpg";

const defaultTheme = createTheme();

export default function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formdata = {
            username,
            pwd: password,
        };

        try {
            const response = await noauthinstance.post("User/login/", formdata);
            if (response.data && response.data.Token) {
                const NewaccessToken = response.data.Token;
                localStorage.setItem('NewaccessToken', NewaccessToken);
                toast.success("User logged in successfully!");
                login(); // Set the login state
                navigate("/dashboard/home");
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400 && error.response.data.non_field_errors) {
                    toast.error("Incorrect credentials. Please check your username and password.");
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.request) {
                toast.error("No response from server.");
            } else {
                toast.error("Request setup error: " + error.message);
            }
        }
    };

    return (
        <div className='log_in_main'>
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: `url(${bgImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div className='login_logo'>
                                <img src={login_logo} alt="Login Logo"/>
                            </div>
                            <div className='login_info'>
                                <h2>Hi there, good day!</h2>
                                <p>Experience seamless access to your account with a quick sign-in to unlock a world of possibilities.</p>
                            </div>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Button
                                    disableRipple
                                    disableElevation
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <ToastContainer />
        </div>
    );
}
